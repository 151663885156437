.profile-pic-container{
    height: 120px;
    width: 120px;
    margin-right: 100px;
    overflow: hidden;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px var(--blue-ny) solid;
}
.profile-pic-container>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.helper-profile{
    margin-top : -20px;
    margin-left : 150px;
}
.helperButtonContainer{
    display : flex;
}
.helperButton{
    margin-left : 20px;
}

@media (max-width:768px){
    .profile-pic-container{
        width: 90px;
        height: 90px;
        margin-right: 32px;
    }
}
