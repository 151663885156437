.dashboard-img-container{
    height: 250px;
    width: 250px;
    margin: auto;
    display: flex;
    justify-content: center;
}

.dashboard-img-container>img{
    width: 100%;
    height: 100%;
}
.partenaire-img, .parrainage-img{
    width: 200px;
    min-width: 200px;
}
.x-icon{
    color : red;
    margin-right : 10px;
}
.check-icon{
    color : green;
    margin-right : 10px;
}
.accomplishment{
    margin-left : 10px;
}

.accomplishment > a:hover{
    color: var(--blue-ny);
    font-size: 105%;
}

.helperButtonContainer{
    display: flex;
}
.helperButton{
    margin-left: 10px;
}
.helperIcon{
    margin-left: -3px;
}