.montant-input {
    position: relative;
    width: 100%;
}

.montant-input>input {
    padding-right: 32px;
}

.montant-input::before {
    content: '€';
    color: var(--primary-font-color);
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.facture-container {
    padding: 6px 16px;
    border: 2px var(--blue-ny) solid;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.helper-facture{
    position: absolute;
    margin-left: 128px;
    margin-top: -43px;
}