.mission-item{
    position: relative;
    padding: 32px;
}


.img-container{
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.img-container>img{
    max-height: 100%;
    max-width: 100%;
}

.mission-link{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.disabled {
	background-color: var(--disabled-grey) !important;
	border: none !important;
	color: grey !important;
	cursor: not-allowed !important;
  }