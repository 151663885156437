.helperButton {
  margin-left: 1em;
}

.ml-3{
    margin-left: 1em;
}

.search-bar .search-bar-input:nth-child(1) {
  background: white;
}

.search-bar .search-bar-input:nth-child(2) {
  background: white;
}

@media (min-width: 768px) {
  .search-bar .search-bar-input:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: white;
    border: 1px solid black;
    color: black;
  }
  .search-bar .search-bar-input:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: white;
    border: 1px solid black;
    border-left: none;
  }
  .search-bar .search-bar-btn {
    width: auto;
    text-align: center;
  }
}
