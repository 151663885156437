.slider {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.slider:hover{
  cursor: pointer;
}

.slider-bg {
  background-color: var(--blue-ny);
}

.slider-content {
  font: normal normal normal 16px/20px Helvetica;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  max-width: 40ch;
  text-align: justify;
}

.slider-dots {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 1.5em;
  transform: scale(2);
}

.slider-title {
  text-align: center;
  margin: auto;
  letter-spacing: -1.3px;
  color: #000000;
  max-width: 9ch;
  font-family: var(--font-family-tt);
  text-transform: uppercase;
  opacity: 1;
  font-size: 8em;
  font-weight: 500;
  line-height: 0.8em;
  margin-bottom: 12px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
