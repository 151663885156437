.navbar {
  position: fixed;
  padding: 16px 0;
  top: 0;
  left: 0;
  background-color: white;
  z-index: var(--navbar-z-index);
}
.nav-link {
  transition: 0.5s color ease;
  -webkit-transition: 0.5s color ease;
  -moz-transition: 0.5s color ease;
  -ms-transition: 0.5s color ease;
  -o-transition: 0.5s color ease;
}
.nav-link:hover {
  font-weight: 900;
}
.nav-link.active {
  color: var(--black-ny);
  font-weight: 900;
}
.nav-link.active:hover {
  color: var(--black-ny);
}
.nav-link {
  color: var(--black-ny);
}
.profile {
  gap: 16px;
}
.profile-picture-container {
  height: 60px;
  width: 60px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.profile-picture-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.nav-dropdown {
  position: absolute;
  flex-direction: column;
  top: calc(100% + 16px);
  right: 12px;
  min-width: 230px;
  gap: 24px 0;
  padding: 16px 0;
  background-color: white;
  box-shadow: 0 14px 22px -8px rgba(24, 39, 75, 0.12),
    0 14px 64px -4px rgba(24, 39, 75, 0.12);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 2;
}
.dropdown-line {
  height: 1px;
  width: 100%;
  background-color: var(--blue-ny);
}

.disponibility-dropdown {
  position: absolute;
  flex-direction: column;
  top: calc(100% + 16px);
  right: 12px;
  min-width: 340px;
  gap: 24px 0;
  padding: 20px 16px;
  background-color: white;
  box-shadow: 0 14px 22px -8px rgba(24, 39, 75, 0.12),
    0 14px 64px -4px rgba(24, 39, 75, 0.12);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 2;
}

.mobile-menu {
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  gap: 16px 0;
  padding-top: 32px;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: white;
  z-index: var(--navbar-mobile-menu-z-index);
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
}
.mobile-menu.open {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}
.mobile-menu-close {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 32px;
  color: #cc444b;
  cursor: pointer;
}
.bell-container {
  position: relative;
  height: 28px;
  width: 28px;
  color: var(--black-ny);
}
.bell-container > *:nth-child(1) {
  height: 100%;
  width: 100%;
}
.bell-container > .new-notif {
  position: absolute;
  top: -2px;
  right: 2px;
  height: 12px;
  width: 12px;
  border: 2px white solid;
  background-color: red;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.close-dropdown {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

@media (max-width: 768px) {
  .profile-picture-container {
    width: 48px;
    height: 48px;
  }
}
