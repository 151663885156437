.fc-daygrid-day, .fc-day-today {
    background-color: var(--background-color) !important;
    cursor: pointer;
}
.fc-day-disabled {
    background-color: var(--disabled-grey) !important;
    cursor: not-allowed;
}
.fc-daygrid-day-top{
    padding-left: 8px;
    flex-direction: row !important;
}
.fc-bg-event {
    background-color:transparent !important;
    opacity: 1 !important;
    color: white;
    z-index: 2 !important;
}
.fc-col-header-cell-cushion{
    color: var(--primary-font-color);
    font-weight: 600;
} 