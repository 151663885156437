.container {
  background-color: white;
  color: black;
  border-radius: 1em;
  margin-top: 6px;
  overflow-x: hidden;
  overflow-y: visible;
  max-height: 150px;
}

.container ul {
  margin: 0;
  padding: 0;
  border-radius: 1em;
}

.container li {
  list-style-type: none;
  padding: 0.5em 1em;
}

.container li:hover {
  background-color: var(--blue-ny);
  color: white;
  cursor: pointer;
  overflow: hidden;
}
