.search-mobile, .filters-mobile {
    padding-top: 80px;
    padding-bottom: 32px;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-y: scroll;
    position: fixed;
    background-color: white;
    z-index: 1000;
    transition: .5s all ease;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    -ms-transition: .5s all ease;
    -o-transition: .5s all ease;
}

.search-mobile {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
}

.search-mobile.open {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}

.filters-mobile {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
}

.filters-mobile.open {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
}

.search-mobile-close {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 32px;
    color: #CC444B;
    cursor: pointer;
}

.filters-mobile-return {
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 40px;
    color: var(--primary-font-color);
    cursor: pointer;
}

.filter-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 200;
    width: 100vw;
    padding: 32px;
    box-shadow: 0 8px 24px rgba(24, 39, 75, 0.08), 0 6px 12px rgba(24, 39, 75, 0.12);
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

@media (max-width:768px) {
    .missions-page {
        padding-bottom: 120px;
    }
}