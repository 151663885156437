.profile-navlink{
    padding: 0 12px;
    border-left: 2px white solid;
    transition: .5s all ease;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    -ms-transition: .5s all ease;
    -o-transition: .5s all ease;
}

.profile-navlink:hover{
    color: var(--blue-ny);
}

.profile-navlink.active{
    color: var(--blue-ny);
    border-left: 2px var(--blue-ny) solid;
}