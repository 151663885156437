.candidature-logo {
  width: 100px;
  height: 100px;
  min-height: 100px;
  min-width: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.candidature-logo > img {
  max-height: 100%;
  max-width: 100%;
}
.candidature-status {
  width: 100%;
  max-width: 300px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.candidature-status[status="En attente"] {
  border: 2px var(--blue-ny) solid;
  color: var(--blue-ny);
}
.candidature-status[status="En attente"]::before {
  content: "Candidature à l'étude";
}
.candidature-status[status="Refusée"] {
  border: 2px var(--red) solid;
  color: var(--red);
}
.candidature-status[status="Refusée"]::before {
  content: "Candidature non retenue";
}
.candidature-status[status="Approuvée"] {
  border: 2px var(--green-ny) solid;
  color: var(--green-ny);
}
.candidature-status[status="Approuvée"]::before {
  content: "Candidature retenue";
}
