.reset-password {
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  max-width: 500px;
}

.white-color {
  color: white !important;
}
