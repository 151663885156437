.helperIcon{
    width : 20px;
    height : 20px;
    margin-top: -10px;
}
.container{
    margin : 5px;
}
.container:link {
    text-decoration: none;
  }
.container:focus { outline-style: none; }
.toolTip{
    z-index: 1000;
}
.toolTipMail{
    width : 500px;
}