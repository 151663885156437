.notification {
    padding: 32px
}

.notification[status='PENDING'] {
    background-color: var(--orange-light-ny);
}

.notification-img {
    width: 48px;
    height: 48px;
    min-height: 48px;
    min-width: 48px;
    overflow: hidden;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification-img>img {
    height: 100%;
    width: 100%;
}