.sign-in {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85%;
  max-width: 500px;
  margin: auto;
  gap: 24px 0;
  background-color: black;
}

.sign-in > img {
  position: fixed;
  top: 33px;
  left: 34px;
  width: 199px;
  height: 38px;
}

.white-color {
  color: white !important;
}

.sign-in > div > input {
  border-radius: 30px;
  padding: 12px 24px;
  border: 1px solid white;
  max-width: 500px;
  margin: auto;
}

.sign-in > div > div > input {
  border-radius: 30px;
  padding: 12px 24px;
  max-width: 500px;
  margin: auto;
  border: 1px solid white;
}

.button-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 500px;
}

.button {
  width: 100%;
  background-color: white;
  border-radius: 30px;
  margin-right: 1px;
  border: none;
  padding: 12px;
  cursor: pointer;
  text-align: center;
}

.left-button {
  width: 100%;
  background-color: white;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-right: 1px;
  height: 60px;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.right-button {
  height: 60px;
  width: 100%;
  border: none;
  background-color: white;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-right: 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-button > img {
  max-width: 40px;
  margin-right: 1em;
}

.left-button > img {
  max-width: 40px;
  margin-right: 1em;
}

.max-width {
  max-width: 500px;
}

.margin-left {
  margin-left: 5px;
}

.margin-left:hover {
  cursor: pointer;
}

.forgotten-password:hover {
  cursor: pointer;
}
