.documents-table{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.documents-table-header{
    background-color: var(--blue-ny);
    border: none;
}
.documents-table-item{
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px var(--blue-ny) solid;
}
.documents-table-item>*{
    width: 15%;
}
.documents-table-item input{
    width: 0 !important;
}
.documents-table-item>*:nth-child(1){
    width: 30%;
}
.documents-table-item>*:nth-child(2){
    width: 15%;
}
.documents-table-item>*:nth-child(3){
    width: 15%;
}
.documents-table-item>*:nth-child(4){
    width: 10%;
}
.documents-table-item>*:nth-child(5){
    width: 15%;
}
.documents-table-item>button{
    width: 15%;
    padding: 6px;
}
.document-status{
    position: relative;
    padding-left: 12px;
}
.document-status::before{
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    height: 10px;
    width: 10px;
    background-color: var(--red);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}
.document-status[status='PENDING']::before{
    background-color: var(--secondary-blue);
}
.document-status[status='APPROVED']::before{
    background-color: var(--blue-ny);
}
.document-status[status='REFUSED']::before{
    background-color: var(--red);
}


.helperButton{
    margin-left:20px;
}


@media (max-width:768px) {
    .documents-table-item{
        padding: 12px;
    }
    .documents-table-item>*{
        width: 30%;
    }
    .documents-table-item>button{
        width: 25%;
        padding: 6px;
        margin-left: 5%;
    }
    .documents-table-item>*:nth-child(1){
        width: 40%;
    }


.documents-table-item>*:nth-child(1){
    width: 35%;
}
.documents-table-item>*:nth-child(2){
    width: 20%;
}
.documents-table-item>*:nth-child(3){
    width: 20%;
}
.documents-table-item>*:nth-child(4){
    width: 20%;
}
.documents-table-item>*:nth-child(5){
    width: 20%;
}
.documents-table-item>*:nth-child(6){
    width: 20%;
}
}