@import "./Typographie.css";
@import "./text-inputs.css";
@import "./buttons.css";
@import "./checkbox.css";
@import "./cards.css";
@import "./radio.css";
@import "./toast.css";
@import "./fullcalendar.css";
@import "./files-loader.css";

:root {
  --primary-font-color: #0e0e2c;
  --primary-green: #048c74;
  --secondary-green: #9bd1c7;
  --secondary-blue: #4c6fff;
  --red: #ee7b81;
  --grey: #e8e9ee;
  --grey-2: #e2e2e2;
  --background-color: #f6f8fa;

  --disabled-grey: #e0e0e0;
  --disabled-btn: var(--green-ny);

  --black-ny: #000000;
  --blue-ny: #a6c3d1;
  --green-ny: #a4bcb0;
  --orange-light-ny: #f4ddc5;

  --grey-background-color: #edf2f7;
  --popup-background-color: rgba(14, 14, 44, 0.25);
  --navbar-z-index: 999;
  --navbar-mobile-menu-z-index: 1000;
  --notifications-z-index: 1001;
  --popup-z-index: 1002;
  --files-loader-z-index: 9999;
  --font-family-regular-helvet: Helvetica, sans-serif;
  --font-family-tt: "TT Trailers";
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  overflow-x: hidden;
  color: var(--primary-font-color);
  height: 100%;
  font-family: var(--font-family-regular-helvet);
}

#root {
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
}

* {
  color: inherit;
  letter-spacing: 0px;
  scroll-behavior: auto;
  margin: 0;
  padding: 0;
  font-family: var(--font-family-regular-helvet);
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--primary-font-color);
}
a:hover {
  color: initial;
}
.cursor-pointer {
  cursor: pointer;
}

.wow-logo {
  height: 48px;
  width: fit-content;
}

.progress-bar-container {
  background-color: var(--grey-2);
  flex: 1;
  height: 16px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}
.progress-bar-progress {
  background-color: var(--blue-ny);
  height: 16px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.disponible {
  fill: var(--blue-ny);
  color: var(--blue-ny);
}
.indisponible {
  fill: var(--blue-ny);
  color: var(--blue-ny);
}
.lastname {
  text-transform: uppercase;
}
.firstname {
  text-transform: lowercase capitalize;
}
.liked {
  fill: var(--red) !important;
  color: var(--red);
}

.green {
  color: var(--blue-ny);
}

.green-ny {
  color: var(--green-ny);
}

.blue-ny {
  color: var(--blue-ny);
}

.my-3-em {
  margin: 3em 0;
}

.underline {
  text-decoration: underline;
}

img.rotated {
  max-width: 100vh;
  max-height: 100vw;
  transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(-90deg);
}

.sign-input {
  width: 100%;
  font-size: 14px;
  color: white;
  padding: 13px;
  border: 2px white solid;
  outline: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  background-color: transparent;
}
.sign-input::placeholder {
  color: white;
}
.sign-input-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 6px;
  color: var(--primary-font-color);
  cursor: pointer;
}
.sign-input:disabled {
  background-color: var(--grey-2);
}

.font-family-tt {
  font-family: "TT Trailers";
  font-size: 3em;
}

.text-divider {
  --text-divider-gap: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  width: 100%;
}

.text-divider::before,
.text-divider::after {
  content: "";
  height: 1px;
  background-color: white;
  flex-grow: 1;
}

.text-divider::before {
  margin-right: var(--text-divider-gap);
}

.text-divider::after {
  margin-left: var(--text-divider-gap);
}

