.ficheck {
  position: relative;
  top: 1em;
  right: -1em;
}

.input {
  margin: auto;
  border-color: var(--green-ny);
}

.max-width{
  max-width: 200px;
  margin-left: 2em;
}