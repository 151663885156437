.error {
  position: relative;
  top: 0.5em;
}

.container {
  width: 80%;
  height: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
}

.container input,
.container select {
  padding: 1em 1.5em;
  border-radius: 2em;
  border: 1px solid white;
}

.container label {
  color: white;
  margin: 1.5em 0;
}

.container section {
  display: flex;
  flex-direction: column;
}

/** STEP 1 CSS ATTRIBUTES **/

.container .step-1-select-number {
  display: flex;
  justify-content: space-between;
}

.step-1-select-number > select {
  padding: 0.5em;
  width: 35%;
}

.phoneNumber {
  display: flex;
  width: 60%;
  padding: 0.5em 1em;
  background-color: white;
  border-radius: 2em;
  align-items: center;
}

.phoneNumber > input {
  outline: none;
  padding: 0 0.5em;
  width: 100%;
}

/** STEP 2 CSS ATTRIBUTES **/

/** STEP 3 CSS ATTRIBUTES **/

/** STEP 4 CSS ATTRIBUTES **/

.pinButton {
  padding: 0.5em;
  color: white;
  background-color: black;
  border: none;
  border-radius: 17px;
  margin-top: 1.5em;
  margin-right: 2em;
}

.pinButton > span {
  margin-left: 1em;
}

/** STEP 5 CSS ATTRIBUTES **/
.average-sale-input {
  width: 100px;
  margin: auto;
  border: none;
  outline: none;
}

.average-sale-button {
  border-radius: 100%;
  border: none;
  text-align: center;
  outline: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: black;
  margin: 0 1em;
}

.average-sale-button:active {
  outline: 1px solid white;
}

/** STEP FINALE **/
.container p {
  color: white;
  font-size: 1.3em;
}

.link {
  color: white;
  background-color: black;
  outline: 3px solid white;
  margin: auto;
  border: none;
  padding: 0.7em 1em;
  border-radius: 2em;
  text-align: center;
}

.link:active,
.link:focus,
.link:checked {
  color: white;
}
