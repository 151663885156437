.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
}

.container > button {
  outline: 3px solid white;
  border: none;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  background-color: black;
  border-radius: 17px;
  padding: 1em 2em;
  color: white;
  width: 100%;
  margin-right: 2em;
  position: relative;
}

.container > button > svg {
  position: absolute;
  top: 1.2em;
  left: 1em;
}

.container > select {
  max-width: 200px;
}

.container > button:hover {
  color: white;
  background-color: var(--blue-ny);
}

@media (max-width: 768px) {
  .container > button {
    padding: 0.5em 1em 0.5em 2em;
  }
}
