.helperButton{
    width : 80px;
    pointer-events: none;
    height: 55px;
}
.helperButtonSmall{
    height: 35px;
}
.container:link {
    text-decoration: none;
  }
.container:focus { outline-style: none; }
.toolTip{
    z-index: 1000;
}