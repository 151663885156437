.auth-layout {
  /* min-height: 100vh; */
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.auth-layout > .left-content {
  width: 100%;
  height: 100vh;
  padding: 2em;
  overflow: auto;
  background-color: var(--black-ny);
}

.left-content > img {
  height: 80px;
}
.auth-layout > .right-content {
  width: 100%;
  height: 100vh;
}

.wow-site:hover {
  cursor: pointer;
}

@media (max-width: 1000px) {
  .auth-layout > .right-content {
    display: none;
  }
}
