.sign-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px 0;
  width: 80%;
  height: 85%;
  margin: auto;
}

.first-block {
  display: flex;
  justify-content: space-between;
}

.first-block input {
  width: 48% !important;
}

.white-color {
  color: white !important;
}

.sign-up input {
  padding: 1em 1.5em;
  border-radius: 2em;
  border: 1px solid white;
  width: 100%;
}

.connect {
  text-decoration: underline;
}

.connect:hover {
  cursor: pointer;
  color: white;
}

.show-pwd {
  position: absolute;
  top: 1.3em;
  right: 1.5em;
  color: black;
}
