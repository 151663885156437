@media (min-width: 768px) {
    .container {
        display: none;
    }
}

.container {
    width: 100%;
}

.mobileView {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    background-color: white;
    animation: translateMobileMenu;
    -webkit-animation: translateMobileMenu;
    animation-duration: 1000ms;
}

.mobileViewContainer{
    padding-left: 2rem;
    padding-top: 2rem;
}

.li {
    list-style: none;
    padding: 1em 0;
}

.li-active {
    color: var(--blue-ny);
}

.ul{
    padding: 0;
    margin-top: 4rem;
}

.close-mobile-menu {
    font-size: 32px;
    color: #cc444b;
    cursor: pointer;
    text-align: center;
}

.mobile-menu-btn {
    position: absolute;
    left: 1.5em;
}

@keyframes translateMobileMenu {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}