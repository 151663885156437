.type-consultant {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px 0;
}

.type-consultant-img-container {
  padding: 12px;
  border: 1px var(--blue-ny) solid;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 180px;
  width: 100%;
  overflow: hidden;
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
}

.type-consultant-img-container.chosen {
  border: 1px var(--blue-ny) solid;
  background-color: var(--blue-ny);
}

.type-consultant-img-container > img {
  min-width: 100%;
}

.upload-container {
  padding: 48px 32px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px 0;
  border: 3px var(--blue-ny) dashed;
  cursor: pointer;
}
.upload-container-mask {
  background-color: var(--secondary-green);
  opacity: 0.7;
  z-index: 1;
  cursor: pointer;
}
.upload-container > * {
  z-index: 1;
}
.id-img {
  width: 250px;
  height: 250px;
}

@media (max-width: 768px) {
  .type-consultant {
    width: 80%;
    max-width: 300px;
  }
}

.link-skill {
  color: blue;
  text-decoration: underline;
}
.link-skill-2 {
  color: blue;
  text-decoration: underline;
}
.link-skill-2-container {
  margin-top: -40px;
}
.document-status {
  position: relative;
  padding-left: 12px;
}
.document-status::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 10px;
  width: 10px;
  background-color: var(--red);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.document-status[status="PENDING"]::before {
  background-color: var(--secondary-blue);
}
.document-status[status="APPROVED"]::before {
  background-color: var(--blue-ny);
}
.document-status[status="REFUSED"]::before {
  background-color: var(--red);
}
