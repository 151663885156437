.forgot-password {
  width: 80%;
  height: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.forgot-password input {
  padding: 1em 1.5em;
  border-radius: 2em;
  margin: 1.5em 0;
  border: 1px solid white;
  width: 100%;
}

.forgot-password button {
  max-width: 300px;
  margin: auto;
}

.white-color {
  color: white !important;
}
