.custom-checkbox {
  height: 20px;
  min-height: 20px;
  width: 20px;
  min-width: 20px;
  border: 2px var(--black-ny) solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  color: var(--black-ny);
  cursor: pointer;
}
.custom-checkbox > * {
  opacity: 0;
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
}
.custom-checkbox ~ label {
  cursor: pointer;
}
input[type="checkbox"]:checked ~ .custom-checkbox {
  background-color: white;
}
input[type="checkbox"]:checked ~ .custom-checkbox > * {
  opacity: 1;
}
