.timesheet-status {
  position: relative;
}
.timesheet-status::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -18px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.timesheet-status[status="DRAFT"]::before {
  background-color: var(--secondary-blue);
}
.timesheet-status[status="PENDING"]::before {
  background-color: var(--orange-light-ny);
}

.timesheet-status[status="PENDING_APPROVAL"]::before {
  background-color: var(--orange-light-ny);
}
.timesheet-status[status="ADMIN_APPROVED"]::before {
  background-color: var(--blue-ny);
}
.timesheet-status[status="REFUSED"]::before {
  background-color: red;
}
.event {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 5;
}
.event.worked-all-day {
  background-color: var(--green-ny);
}
.event.worked-half-day {
  background-color: var(--orange-light-ny);
}

.prev-btn,
.next-btn {
  font-size: 18px;
  padding: 4px 8px;
  background-color: var(--grey-2);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  cursor: pointer;
}
.circle {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  flex-shrink: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.circle.worked-all-day {
  background-color: var(--green-ny);
}
.circle.worked-half-day {
  background-color: var(--orange-light-ny);
}
.circle.absent {
  background-color: var(--background-color);
}

.submit-btn-disabled {
  background-color: var(--disabled-btn);
  border: none;
  color: white;
}
.helperButtonContainer{
    display: flex;
}
.helperButton{
  margin-left : 20px;
}
@media (max-width: 1400px) {
  
  .legend {
    display : block !important;
  }
  .legend-button{
    margin-top : 10px;
    width : 100%;
  }
}
@media (max-width:1400px) and (min-width:768px){
  .date-button{
    width: 371px;
  }
}

@media (min-width:768px){
  .fullDisplayCalendar{
    width: 100%;
  }

  .fullDisplayCalendar > .timesheet-status{
    margin-left: 2em;
  }
}

@media (max-width:768px){

  .fullDisplayCalendar > .timesheet-status{
    margin-top: 1em;
  }
}

.max-h-btn{
  max-height: 60px;
  width: 300px;
  margin: auto;
}