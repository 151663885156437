.default-btn {
  background-color: var(--black-ny);
  border: 1px var(--black-ny) solid;
  width: 100%;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: 16px 24px;
  border-radius: 17px;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  -ms-border-radius: 17px;
  -o-border-radius: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
}

.default-btn:hover {
  border: 1px solid black;
  color: var(--black-ny);
  background-color: white;
}

.default-btn > svg {
  font-size: 18px;
}
.default-btn.primary-btn {
  background-color: white;
  border: 1px var(--blue-ny) solid;
  color: var(--blue-ny);
}


.default-btn.primary-btn:hover {
  background-color: var(--blue-ny);
  color: white;
}
.default-btn.secondary-btn {
  background-color: var(--green-ny);
  color: white;
}
.default-btn.blue-btn {
  background-color: transparent;
  border: 1px var(--blue-ny) solid;
  color: var(--blue-ny);
}
.default-btn.danger-btn {
  background-color: transparent;
  border: 1px var(--red) solid;
  color: var(--red);
}
.default-btn.danger-btn-2 {
  background-color: var(--red);
  border: 1px var(--red) solid;
  color: white;
}

.sign-btn {
  outline: none;
  font-size: 0.85em;
  padding: 0.75em 1em;
  border-radius: 17px;
  margin-top: 1em;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  -ms-border-radius: 17px;
  -o-border-radius: 17px;
  background-color: white;
  color: black;
  border: none;
}

.sign-btn:hover {
  transform: scale(1.05);
}
