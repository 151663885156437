.contracts-table{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.contracts-table-header{
    background-color: var(--blue-ny);
    border: none;
}
.contracts-table-item{
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px var(--blue-ny) solid;
}
.contracts-table-item>*{
    width: 20%;
}
.contract-status{
    position: relative;
    padding-left: 12px;
}
.contract-status::before{
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    height: 10px;
    width: 10px;
    background-color: var(--red);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}
.contract-status[status='PENDING']::before{
    background-color: var(--orange-light-ny);
}
.contract-status[status='ONGOING']::before{
    background-color: var(--blue-ny);
}
.contract-status[status='EXPIRED']::before{
    background-color: var(--red);
}
@media (max-width:768px) {
    .contracts-table-item{
        padding: 12px;
    }
    .contracts-table-item>*{
        width: 50%;
    }
}