.upload-container {
  position: relative;
  padding: 48px 32px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px 0;
  border: 5px var(--blue-ny) dashed;
}

.cv-img {
  height: 300px;
  max-width: 300px;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  width: 18px;
  height: 18px;
  color: var(--primary-font-color);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.search-input {
  padding-left: 36px;
}

.search-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px var(--blue-ny) solid;
  border-top: none;
  z-index: 2;
}

.search-content > div {
  width: 100%;
  padding: 8px 10px;
  cursor: pointer;
}
.close-search-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 0;
}
.add-ref {
  height: 200px;
  width: 200px;
  border: 5px var(--blue-ny) dashed;
  color: var(--blue-ny);
  font-size: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.skill {
  position: relative;
  padding: 8px 24px;
  background-color: var(--blue-ny);
  color: white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.reference {
  border: 2px var(--blue-ny) solid;
  position: relative;
}
.delete-reference {
  position: absolute;
  height: 24px;
  width: 24px;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.accordion-form {
  max-height: 0px;
  overflow: hidden;
  transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  -moz-transition: 0.3s all ease;
  -ms-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
}

.counter{
  text-align: right;
}
.checkbox-container{
  width: 25%;
}

.link-skill{
  color : blue;
  text-decoration: underline;
}
.helperButtonContainer{
  display : flex;
}
.helperButton{
  margin-left : 20px;
}

@media (max-width:768px){
  .checkbox-container{
      width: 100%;
  }
}