.mission-header {
  position: relative;
  padding: 32px;
  padding-top: 180px;
}

.mission-header > .cover-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 240px;
  width: 100%;
  overflow: hidden;
  padding: 0;
  z-index: 0;
  pointer-events: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.cover-img > img {
  height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.mission-header > *:nth-child(2) {
  z-index: 1;
}

.mission-header .entreprise-logo {
  width: 120px;
  height: 120px;
  min-height: 120px;
  min-width: 120px;
  padding: 12px;
  background-color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.entreprise-logo > img {
  max-height: 100%;
  max-width: 100%;
}

.mission-like {
  position: absolute;
  top: 16px;
  right: 16px;
  width: fit-content;
  fill: white;
  transition: 0.2s all ease-in;
  -webkit-transition: 0.2s all ease-in;
  -moz-transition: 0.2s all ease-in;
  -ms-transition: 0.2s all ease-in;
  -o-transition: 0.2s all ease-in;
}
.like {
  fill: white;
  transition: 0.2s all ease-in;
  -webkit-transition: 0.2s all ease-in;
  -moz-transition: 0.2s all ease-in;
  -ms-transition: 0.2s all ease-in;
  -o-transition: 0.2s all ease-in;
}
.options-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 200;
  width: 100vw;
  padding: 32px;
  box-shadow: 0 8px 24px rgba(24, 39, 75, 0.08),
    0 6px 12px rgba(24, 39, 75, 0.12);
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

@media (max-width: 768px) {
  .mission-page {
    padding-bottom: 120px;
  }
  .cover-img > img {
    transform: translateX(-40%);
    -webkit-transform: translateX(-40%);
    -moz-transform: translateX(-40%);
    -ms-transform: translateX(-40%);
    -o-transform: translateX(-40%);
  }
}

.mission-description,
pre {
  white-space: break-spaces;
  font-size: inherit;
  color: inherit;
  border: initial;
  padding: initial;
  font-family: inherit;
}
