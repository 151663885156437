.customCheckbox {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
    box-sizing: border-box;
    flex-shrink: 0;
}

.customCheckbox.checked {
    background-color: black;
    border-color: black;
}

.checkIcon {
    color: white;
    font-size: 16px;
}