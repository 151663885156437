.facturation-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.facturation-table-header {
  background-color: var(--blue-ny);
  border: none;
}
.facturation-table-item {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px var(--blue-ny) solid;
}

.facturation-table-item input {
  width: 0 !important;
}

.facturation-table-item > * {
  display: flex;
  justify-content: space-between;
}

.facturation-table-item > :nth-child(1) {
  width: 50%;
}

.facturation-table-item > :nth-child(1) > * {
  width: 100%;
}

.facturation-table-item > :nth-child(2) {
  width: 30%;
}

.facturation-table-item > button {
  width: 15%;
  padding: 6px;
}
/* .facturation-status {
  position: relative;
  padding-left: 16px;
  text-align: center;
} */
.facturation-status[status="REFUSED"]::after {
  content: "(refusée)";
  margin-left: 0.5em;
  color: var(--red);
}
/* .facturation-status[status="PENDING"]::before {
  background-color: var(--secondary-blue);
}
.facturation-status[status="APPROVED"]::before {
  background-color: var(--blue-ny);
}
.facturation-status[status="PAYED"]::before {
  background-color: var(--blue-ny);
} */

/* .facturation-status[status="REFUSED"]::before {
  background-color: var(--red);
} */
.facturation-illustration {
  width: 100%;
  max-width: 240px;
}
@media (max-width: 768px) {
  .facturation-table-item {
    padding: 12px;
  }
  .facturation-table-item > * {
    width: 30%;
  }
  .facturation-table-item > button {
    width: 25%;
    padding: 6px;
    margin-left: 5%;
  }
  .facturation-table-item > *:nth-child(1) {
    width: 40%;
  }
}
