.default-input {
    width: 100%;
    font-size: 14px;
    color: var(--primary-font-color);
    padding: 13px;
    border: 2px var(--blue-ny) solid;
    outline: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: transparent;
}
.default-input::placeholder{
    color: grey;
}
.default-input-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 6px;
    color: var(--primary-font-color);
    cursor: pointer;
}
.default-input:disabled{
    background-color: var(--grey-2);
}
.show-pwd{
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    color: white;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    cursor: pointer;
}

.text-area{
    min-height: 200px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}