.mission-item{
    position: relative;
    padding: 32px;
    padding-bottom: 16px;
}

.mission-item>.matching{
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--secondary-blue);
    color: white;
    padding: 4px 12px;
    border-top-left-radius: 10px;
    font-size: 14px;
}

.mission-item>.like{
    position: absolute;
    top: 8px;
    right: 12px;
    font-size: 20px;
    cursor: pointer;
    z-index: 10;
    fill: transparent;
    transition: .1s all ease;
    -webkit-transition: .1s all ease;
    -moz-transition: .1s all ease;
    -ms-transition: .1s all ease;
    -o-transition: .1s all ease;
}
.img-container{
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.img-container>img{
    max-height: 100%;
    max-width: 100%;
}

.mission-link{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}