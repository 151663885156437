.switch{
    position: relative;
    height: 24px;
    min-height: 24px;
    width: 48px;
    min-width: 48px;
    cursor: pointer;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.switch *{
    transition: .3s all ease;
    -webkit-transition: .3s all ease;
    -moz-transition: .3s all ease;
    -ms-transition: .3s all ease;
    -o-transition: .3s all ease;
    pointer-events: none;
}
.switch-checkbox{
    opacity: 0;
    height: 0;
    width: 0;
    display: none;
}
.switch-circle{
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    transform: translateX(2px);
    -webkit-transform: translateX(2px);
    -moz-transform: translateX(2px);
    -ms-transform: translateX(2px);
    -o-transform: translateX(2px);
    z-index: 2 !important;
    position: relative;
}
.switch-bg{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--blue-ny);
    z-index: 1;
}
.switch-checkbox:checked + .switch-circle{
    transform: translateX(26px);
    -webkit-transform: translateX(26px);
    -moz-transform: translateX(26px);
    -ms-transform: translateX(26px);
    -o-transform: translateX(26px);
}
.switch-checkbox:checked ~ .switch-bg{
    background-color: var(--blue-ny);
}