.notifications-container{
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -1001;
    background-color: transparent;
    transition-property: z-index;
    transition-delay: .5s;
}
.notifications-container.open{
    background-color: var(--popup-background-color);
    z-index: var(--notifications-z-index);
    transition-delay: .5s;
    transition-property: background-color;
}
.notifications-container-bg{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1;
}
.notifications{
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    max-width: 440px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 4px 0;
    padding: 16px 0;
    z-index: 2;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transition: .5s all ease;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    -ms-transition: .5s all ease;
    -o-transition: .5s all ease;
    overflow-y: scroll ;
}
.notifications.open{
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
}